//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-56:_9488,_9968,_2252,_2404,_8220,_2036,_3268,_3588;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-56')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-56', "_9488,_9968,_2252,_2404,_8220,_2036,_3268,_3588");
        }
      }catch (ex) {
        console.error(ex);
      }