import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-spark'],
  page: {
    // prevent redirect to the search page
    getPageType: (defaults) =>
      document.querySelector('body.page-type-default') ? 'search' : defaults.getter(),
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter((w) => w.name !== 'HomeVehicleWidget'),
    {
      name: 'HomeSearchPage',
      type: 'SearchPage',
      location: { selector: '#cm_home-search-page', class: 'cm_home-search-page productGrid--maxCol3' },
      template: 'SearchPage',
    },
  ],
};
